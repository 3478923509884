$white-color: #fff;
$text-color: #555;
$primary-color: #193057;
$primary-one-color:#a7bbdf;
$secondary-one-color:#DE9A09;
$red-color: #EC1A25;
$border-color: #ddd;
$primary-light-color: #E8F1FF;
$grey-color: #f5f5f5;
$secondary-color: #FDB51F;
$black-overlay: rgba(0, 0, 0, 0.5);
$dark-grey-color: #D5D6DE;
$light-grey-color: #E6E6EC;
$lighter-grey-color: #EDEDF1;
$lighted-grey-color: #EFEFEF;
$box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
$box-shadow1: 0 2px 10px rgba(0, 0, 0, 0.1);

@font-face {
    font-family: 'Helvetica Now Display Bold Italic';
    src: url('../font/HelveticaNowDisplay-BoldIta.eot');
    src: local('Helvetica Now Display Bold Ita'), local('HelveticaNowDisplay-BoldIta'),
        url('../font/HelveticaNowDisplay-BoldIta.eot?#iefix') format('embedded-opentype'),
        url('../font/HelveticaNowDisplay-BoldIta.woff2') format('woff2'),
        url('../font/HelveticaNowDisplay-BoldIta.woff') format('woff'),
        url('../font/HelveticaNowDisplay-BoldIta.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Display Regular';
    src: url('../font/HelveticaNowDisplay-RegIta.eot');
    src: local('Helvetica Now Display Reg Ita'), local('HelveticaNowDisplay-RegIta'),
        url('../font/HelveticaNowDisplay-RegIta.eot?#iefix') format('embedded-opentype'),
        url('../font/HelveticaNowDisplay-RegIta.woff2') format('woff2'),
        url('../font/HelveticaNowDisplay-RegIta.woff') format('woff'),
        url('../font/HelveticaNowDisplay-RegIta.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Display Medium Italic';
    src: url('../font/HelveticaNowDisplay-MedIta.eot');
    src: local('Helvetica Now Display Med Ita'), local('HelveticaNowDisplay-MedIta'),
        url('../font/HelveticaNowDisplay-MedIta.eot?#iefix') format('embedded-opentype'),
        url('../font/HelveticaNowDisplay-MedIta.woff2') format('woff2'),
        url('../font/HelveticaNowDisplay-MedIta.woff') format('woff'),
        url('../font/HelveticaNowDisplay-MedIta.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Display Bold';
    src: url('../font/HelveticaNowDisplay-Bold.eot');
    src: local('Helvetica Now Display Bold'), local('HelveticaNowDisplay-Bold'),
        url('../font/HelveticaNowDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/HelveticaNowDisplay-Bold.woff2') format('woff2'),
        url('../font/HelveticaNowDisplay-Bold.woff') format('woff'),
        url('../font/HelveticaNowDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Display Regular';
    src: url('../font/HelveticaNowDisplay-Regular.eot');
    src: local('Helvetica Now Display Regular'), local('HelveticaNowDisplay-Regular'),
        url('../font/HelveticaNowDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('../font/HelveticaNowDisplay-Regular.woff2') format('woff2'),
        url('../font/HelveticaNowDisplay-Regular.woff') format('woff'),
        url('../font/HelveticaNowDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Display Medium';
    src: url('../font/HelveticaNowDisplay-Medium.eot');
    src: local('Helvetica Now Display Medium'), local('HelveticaNowDisplay-Medium'),
        url('../font/HelveticaNowDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('../font/HelveticaNowDisplay-Medium.woff2') format('woff2'),
        url('../font/HelveticaNowDisplay-Medium.woff') format('woff'),
        url('../font/HelveticaNowDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Atak Medium';
    src: url('../font/AtakMedium.woff2');
    src: local('Atak Medium'), local('AtakMedium'),
        url('../font/AtakMedium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Atak Regular';
    src: url('../font/AtakRegular.woff2');
    src: local('Atak Regular'), local('AtakRegular'),
        url('../font/AtakRegular.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Atak Bold';
    src: url('../font/AtakBold.woff2');
    src: local('Atak Bold'), local('AtakBold'),
        url('../font/AtakBold.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

body {
    color: $text-color;
    font-family: Atak Regular;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Helvetica Now Display Bold';
    }

    a {
        color: $text-color;
        font-family: 'Helvetica Now Display Medium';
        cursor: pointer;
        text-decoration: none !important;
        -webkit-transition: all .35s ease-in-out;
        -moz-transition: all .35s ease-in-out;
        -o-transition: all .35s ease-in-out;
        -ms-transition: all .35s ease-in-out;
        transition: all .35s ease-in-out;

        &:hover {
            color: $primary-color;
            text-decoration: none;
            font-weight: 400;
        }
    }

    .autorenew_check {
        margin-bottom: 10px;

        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
            padding: 0;
            padding-right: 5px;
        }

        .css-i4bv87-MuiSvgIcon-root {
            font-size: 1.9rem;
            padding: 0;
            fill: $primary-color;
        }

        span {
            font-size: 14px;
            font-family: "Atak Regular";
            padding-left: 0;
        }
    }

    .custom_file_group {
        height: 50px !important;

        .custom-file-label {
            padding: 13px 20px 14px 20px !important;

            &::after {
                top: 10px !important;
                font-size: 12px !important;
            }
        }
    }

    .static_refresh {
        img {
            animation: none;
        }
    }

    .main_title {
        color: $primary-color;
        font-family: 'Helvetica Now Display Bold';
        font-size: 25px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 30px;
    }

    .animated_refresh {
        img {
            -webkit-animation: spin 1.5s linear infinite;
            -moz-animation: spin 1.5s linear infinite;
            animation: spin 1.5s linear infinite;
        }
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    input {
        &::placeholder {
            color: $text-color;
            opacity: 1;
        }
    }

    .logo_note {
        margin: 0 !important;
        margin-top: 5px !important;
        color: $primary-color !important;
    }

    .css-1k51tf5-MuiTooltip-tooltip {
        font-size: 12px;
    }

    .blue_text {
        color: $primary-color;
    }

    .button_block {
        text-align: right;
        padding-bottom: 20px;

        .links {
            margin-left: 15px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.2px;
            padding: 14px 18px;
            display: inline-block;
            border: 1px solid $primary-color;
            background-color: transparent;
            border-radius: 50px;
            text-transform: uppercase;

            &:hover {
                background-color: $primary-color;
                color: $white-color;
            }
        }
    }

    .drop_menu {
        margin-top: 15px;

        ul {
            padding: 15px;
            border-radius: 10px;
            color: $text-color;

            li {
                font-size: 14px;
                font-family: 400;
                color: $text-color;
                font-family: "Helvetica Now Display Regular";

                &.divider {
                    height: 1px;
                    margin: 5px 0;
                    overflow: hidden;
                    background-color: #e5e5e5;
                }

                &:hover {
                    color: $primary-color;
                }
            }

            .material-icons {
                margin-right: 7px;
                margin-top: 2px;
                font-size: 20px;
            }
        }

    }

    .validation_notes {
        font-size: 12px;
        font-weight: 400;
        color: $red-color;
        margin: 0;
        margin-top: 5px;
        display: block;
    }

    .counter_text {
        font-size: 12px;
        font-weight: 400;
        color: $secondary-color;
        margin: 0;
        margin-top: 5px;
        display: block;
        text-align: end;

    }

    .search-bar {
        top: 0;

        input[type="text"] {
            padding: 31px 60px 31px 56px;
        }

        .search-icon {
            top: 26px;
        }

        .close-search {
            top: 22px;
        }
    }

    .search_bar {
        position: relative;

        fieldset {
            border: 1px solid $border-color;
        }
    }

    .search_close {
        display: block;
        padding: 0;
        min-width: auto;
        position: absolute;
        right: 30px;
        top: 30px;

        img {
            width: 20px;
        }

        &:hover {
            background-color: transparent;
        }
    }

    .drop_menu {
        display: inline-block;
        padding: 0;
        min-width: auto;
        color: $text-color;

        &:hover {
            background-color: transparent;
        }
    }

    .d-flex {
        display: flex;
    }

    .align-self-center {
        align-self: center;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right !important;
    }

    .no-gutter {
        margin-right: 0;
        margin-left: 0;
    }

    .no-gutter>[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    .drop_menus {
        font-size: 14px;
        font-style: normal;

        .placeholder {
            color: $text-color;
            font-style: normal;
        }
    }

    iframe {
        border: none;
    }

    .error_class {
        background-color: $white-color;
        padding: 30px;
        border-radius: 0;
        margin-bottom: 0;

        h2 {
            font-size: 40px;
            font-weight: 700;
            color: $primary-color;
            margin-bottom: 30px;
        }

        p {
            font-size: 25px;
            font-weight: 700;
            font-family: "Helvetica Now Display Bold";
            color: $text-color;
            line-height: 40px;
            margin: 30px auto;
            max-width: 750px;
        }

        .button_block {
            text-align: center;

            .links {
                margin-left: 15px;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.2px;
                padding: 14px 18px;
                display: inline-block;
                border: 1px solid $primary-color;
                background-color: $white-color;
                border-radius: 50px;
                text-transform: uppercase;

                &:hover {
                    background-color: $primary-color;
                    color: $white-color;
                }
            }
        }
    }

    .dialog_box_popup {
        .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
            border-radius: 10px;
        }

        .dialog_box_block {
            .dialog_box_title {
                padding: 20px 30px;
                background-color: $primary-color;
                display: flex;
                justify-content: space-between;
                margin: 0;

                h6 {
                    font-size: 20px;
                    font-weight: 700;
                    color: $white-color;
                    margin: auto 0;
                    margin-right: 70px;
                }

                button {
                    min-width: 25px;
                    max-width: 100%;
                    padding: 0;
                }
            }

            .dialog_box_detail {
                padding: 20px 30px;
                margin: 0;

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: $text-color;
                    margin: 0;
                }
            }
        }

        .dialog_box_button {
            padding: 0 30px 20px 30px;
            text-align: right;

            button {
                margin-left: 15px;
                font-size: 14px;
                font-weight: 500;
                font-family: "Helvetica Now Display Medium";
                letter-spacing: 0.2px;
                padding: 14px 18px;
                display: inline-block;
                border: 1px solid $primary-color;
                color: $primary-color;
                background-color: $white-color;
                border-radius: 50px;
                text-transform: uppercase;

                &:hover {
                    background-color: $primary-color;
                    color: $white-color;
                }
            }
        }
    }

    .login_block {
        display: block;
        background-color: $white-color;
        height: 100vh;
        background-image: url(../images/signin_bg.png);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        padding-top: 30px;

        .back_block {
            padding: 40px 30px;
            max-width: 500px;
            margin: auto;
            background-color: $white-color;
            box-shadow: $box-shadow1;
            border-radius: 30px;
            text-align: center;

            .arrow_block {
                display: flex;
                justify-content: space-between;

                .back_arrow {
                    width: 40px;
                    height: 40px;
                    display: block;
                    border: 1px solid $primary-color;
                    border-radius: 50%;
                    position: relative;
                    margin-bottom: 30px;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: $text-color;
                    margin: auto 0;

                    a {
                        color: $primary-color;
                        font-weight: 500;
                        font-family: "Atak Medium";
                    }
                }
            }

            .login_title_block {
                padding: 0;

                figure {
                    margin-bottom: 30px;
                }

                h1 {
                    font-size: 50px;
                    font-weight: 700;
                    color: $primary-color;
                    margin: 0;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: $text-color;
                    margin: 0;
                    margin-bottom: 50px;
                    text-align: center;
                }

                .form_block {
                    .row {
                        margin-left: -5px;
                        margin-right: -5px;

                        .col-md-3,
                        .col-sm-4,
                        .col-xs-6 {
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                    }

                    .input-group {
                        margin-bottom: 40px;

                        &.icon_field {
                            position: relative;
                            margin-bottom: 5px;

                            .eye_icon {
                                top: 8px;
                                right: 15px;
                                position: absolute;
                                z-index: 2;
                            }
                        }

                        .input-group-addon {
                            vertical-align: top;
                        }

                        input[type="text"],
                        .form-control {
                            background-color: transparent;
                            border-bottom: 1px solid $border-color;
                            padding: 10px 20px 16px 0;
                            height: auto;
                            font-size: 16px;
                            border-radius: 0;
                            color: $text-color;
                            height: 40px;
                            text-align: left;
                            font-style: normal;

                            &:focus {
                                color: $primary-color;
                                border-bottom: 1px solid $primary-color;
                            }
                        }
                    }

                    .login_button_block {
                        display: block;

                        .btn-primary {
                            padding: 9px 50px;
                            border-radius: 50px;
                            font-size: 16px;
                            font-weight: 500;
                            font-family: "Helvetica Now Display Medium";
                            background-color: $primary-color !important;
                            display: inline-block;

                            img {
                                margin-left: 50px;
                            }
                        }

                        p {
                            font-size: 16px;
                            font-weight: 500;
                            font-family: "Helvetica Now Display Medium";
                            margin: 0;
                            color: $text-color;
                            display: inline-block;
                            margin: auto;
                        }

                        a {
                            width: 44px;
                            height: 44px;
                            display: block;
                            border: 1.5px solid $primary-color;
                            border-radius: 50%;
                            margin-left: 15px;
                            position: relative;

                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
            }
        }

        .login_background {
            display: block;
            position: relative;

            img {
                width: 100%;
            }

            .sign_in_image {
                position: absolute;
                width: 120px;
                height: 120px;
                display: block;
                background-color: $white-color;
                border-radius: 50%;
                top: 120px;
                right: 300px;

                img {
                    max-width: 100%;
                    width: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &.two {
                    width: 100px;
                    height: 100px;
                    top: 320px;
                    right: 430px;
                }

                &.three {
                    width: 90px;
                    height: 90px;
                    top: 287px;
                    right: 146px;
                }

                &.four {
                    width: 72px;
                    height: 72px;
                    top: 450px;
                    right: 266px;
                }
            }

            .your_data_block {
                display: flex;
                justify-content: start;
                position: absolute;
                top: 590px;
                right: 130px;
                background-color: $white-color;
                padding: 30px;
                border-radius: 22px;

                .data_line_block {
                    margin-right: 50px;

                    .line {
                        width: 96px;
                        height: 8px;
                        border-radius: 10px;
                        background-color: $dark_grey-color;
                        margin-bottom: 27px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &.white {
                            padding: 9px;
                            background-color: $white-color;
                            box-shadow: $box-shadow;
                            width: 83px;
                            height: 25px;
                            border-radius: 30px;
                        }

                        &.blue {
                            background-color: $primary-color;
                            width: 65px;
                            margin-bottom: 0;
                        }

                        &.grey {
                            width: 62px;
                            background-color: $light-grey-color;
                        }

                        &.lightgrey {
                            width: 73px;
                            background-color: $lighter-grey-color;
                        }

                        &.lightergrey {
                            width: 84px;
                            background-color: $lighted-grey-color;
                        }
                    }
                }

                .your_data {
                    margin-right: 30px;

                    img {
                        width: auto;
                        margin-bottom: 25px;
                    }

                    h6 {
                        font-size: 18px;
                        font-weight: 700;
                        color: $primary-color;
                        margin: 0;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        color: $text-color;
                        margin: 0;
                        max-width: 170px;
                    }
                }
            }
        }
    }

    .props_detail {
        display: block;

        .property_list_image {
            img {
                max-width: 100%;
            }

            .carousel {
                position: relative;
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
            }

            .carousel-image {
                width: 100%;
                height: 240px;
            }

            .carousel-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background: rgba(255, 255, 255, 0.5);
                border: none;
                color: #333;
                font-size: 2em;
                padding: 0.5em 1em;
                cursor: pointer;
            }

            .carousel-button:focus {
                outline: none;
            }

            .carousel-button:first-child {
                left: 0;
            }

            .carousel-button:last-child {
                right: 0;
            }
        }

        .property_list_detail {
            display: block;

            h2 {
                font-size: 16px;
                font-weight: 700;
                color: $primary-color;
                margin: 0;
                margin-bottom: 10px;
                white-space: break-spaces;
                word-break: break-word;
            }

            .amenities_details {
                padding-bottom: 10px;
                border-bottom: 1px solid $border-color;
                margin-bottom: 20px;

                p.status {
                    padding: 5px 8px;
                    border-radius: 3px;
                    color: #fff;
                    display: inline-block;
                    font-size: 10px;
                    font-weight: 400;
                    margin: 0;
                    margin-right: 10px;

                    &.green {
                        background-color: $secondary-color;
                    }
                }

                .amenities_block {
                    margin: 0 10px;
                    display: inline-block;

                    img {
                        margin-right: 5px;
                    }

                    span {
                        font-size: 10px;
                        font-weight: 400;
                        color: $text-color;
                    }
                }
            }

            .quality_score_block {
                display: block;

                .services_detail {
                    margin-bottom: 15px;
                    display: block;

                    p {
                        font-size: 10px;
                        font-weight: 400;
                        color: $text-color;
                        margin: 0;
                        padding: 0 20px;
                        border-right: 1px solid $border-color;
                        display: inline-block;

                        &:first-child {
                            padding-left: 0;
                        }

                        &:last-child {
                            padding-right: 0;
                            border-right: none;
                        }

                        span {
                            opacity: 0.7;
                        }
                    }
                }

                .amenities_detail_block {
                    margin-bottom: 15px;

                    p.status {
                        padding: 5px 8px;
                        border-radius: 3px;
                        color: #fff;
                        display: inline-block;
                        font-size: 10px;
                        font-weight: 400;
                        margin: 0;
                        margin-right: 10px;
                        margin-bottom: 5px;

                        &:last-child {
                            margin-right: 0x;
                        }

                        &.green {
                            background-color: $secondary-color;
                        }

                        &.blue {
                            background-color: $primary-color;
                        }
                    }
                }

                .quality_block {
                    display: block;

                    h3 {
                        font-size: 12px;
                        font-weight: 700;
                        color: $primary-color;
                        margin: 0;
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 10px;
                        font-weight: 400;
                        color: $text-color;
                        margin: 0;
                        margin-bottom: 10px;
                    }

                    .services_detail {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .modal_block {
        max-width: 650px;
        float: right;
        margin: 10px 25px;
        border: none;
        outline: none;

        &.upgrade {
            max-width: 1000px;
        }

        &.add_requirements {
            max-width: 100%;

            .type_categories_block {
                margin: 0;

                .full_width_dropdown {
                    margin-bottom: 40px;
                    margin-left: 20px;
                    margin-right: 20px;

                    .form-group {
                        margin: 0;
                        margin-bottom: 20px;
                        width: 100%;
                    }

                    a {
                        background-color: $primary-color;
                        color: $white-color;
                        font-size: 14px;
                        font-weight: 500;
                        font-family: "Helvetica Now Display Medium";
                        border-radius: 30px;
                        padding: 15px 18px;
                        display: inline-block;
                        text-transform: uppercase;
                    }
                }

                .form-group {
                    margin-bottom: 20px;
                    position: relative;

                    .form-control {
                        background-color: $white-color;
                        border: 1px solid $border-color;
                        padding: 16px 20px;
                        height: auto;
                        font-size: 14px;
                        font-weight: 400;
                        border-radius: 7px;
                        color: $text-color;
                        height: 50px;
                        
                        &.auto_complete {
                            padding: 0;

                            input {
                                font-size: 14px;
                                margin-bottom: 0;
                                color: $text-color;
                                font-weight: 400;
                                margin-top: -3px;
                                padding: 9px 11px;

                                input::placeholder {
                                    color: $text-color;
                                }

                                input::-webkite-inline-placeholder {
                                    color: $text-color;
                                }
                            }

                            .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                                border: none;
                            }

                            div.MuiAutocomplete-root.MuiPopper-root.MuiAutocomplete-popperDisable.portal.css-1rndd9j-MuiPopper-root-MuiAutocomplete-popper {
                                font-size: 14px;
                                margin-bottom: 0;
                                color: $text-color;
                                font-weight: 400;
                            }

                            .css-1q60rmi-MuiAutocomplete-endAdornment {
                                top: calc(50% - 12px);
                                right: 20px;
                                color: $text-color;
                            }
                        }

                        .MuiSelect-select {
                            padding: 0;
                            font-style: normal;
                        }

                        fieldset {
                            border-color: transparent;
                        }

                        .select_text {
                            font-style: normal;
                        }

                        svg {
                            right: 20px;
                            color: $text-color;
                        }
                    }
                }


                h5 {
                    font-size: 20px;
                    font-weight: 700;
                    color: $primary-color;
                    margin: 0 20px;
                    margin-bottom: 20px;
                    font-family: "Helvetica Now Display Bold";
                }

                .assessment_range_block {
                    margin-bottom: 40px;
                    margin-left: 20px;
                    margin-right: 20px;

                    .budget_range {
                        padding: 9px 0;
                    }
                }

                .type_block {
                    &.property_type {
                        margin: 40px 0;

                        h5 {
                            margin-left: 0;
                            margin-right: 0;
                        }

                        img {
                            max-width: 60px;
                        }
                    }
                }
            }
        }

        .modal_header_block {
            background-color: $primary-color;
            border-radius: 10px 10px 0 0;
            padding: 20px 30px;
            display: flex;
            justify-content: space-between;

            h2 {
                font-size: 20px;
                font-weight: 700;
                color: $white-color;
                margin: auto 0;
            }

            button {
                background-color: transparent;
                border: none;
            }
        }

        .modal_footer_block {
            display: block;
            padding: 20px 30px 20px 30px;
            background-color: $white-color;
            border-radius: 0 0 10px 10px;

            .button_block {
                text-align: right;
                padding-bottom: 0;

                .links {
                    margin-left: 15px;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    padding: 14px 18px;
                    display: inline-block;
                    border: 1px solid $primary-color;
                    background-color: $white-color;
                    border-radius: 50px;
                    text-transform: uppercase;

                    &:hover {
                        background-color: $primary-color;
                        color: $white-color;
                    }
                }
            }
        }

        .modal_body_block {
            padding: 20px 30px;
            background-color: $white-color;
            border-radius: 0;
            max-height: 350px;
            overflow-y: auto;

            &.requirements_body_block {
                max-width: 1100px;
            }

            .publish_properties_block {
                p {
                    margin: 0;
                    margin-top: 10px;
                    margin-bottom: 10px;

                    span {
                        &.red_text {
                            color: $red-color;
                        }
                    }
                }

                h6 {
                    font-size: 16px;
                    font-weight: 500;
                    font-family: "Atak Medium";
                    margin: 0;
                    color: $primary-color;
                }
            }

            .credit_numbers {
                display: flex;
                justify-content: space-between;

                .avail_credits {
                    margin-left: 70px;

                    p.blue_text {
                        color: $primary-color;

                        img {
                            margin-right: 5px;
                        }
                    }
                }
            }

            .upgrade_plan_block {
                display: flex;
                justify-content: space-between;
                gap: 30px;

                .upgrade_plan_detail {
                    padding: 20px;
                    width: 50%;
                    border: 1px solid $border-color;
                    display: block;
                    border-radius: 10px;
                    -webkit-transition: all .35s ease-in-out;
                    -moz-transition: all .35s ease-in-out;
                    -o-transition: all .35s ease-in-out;
                    -ms-transition: all .35s ease-in-out;
                    transition: all .35s ease-in-out;

                    &.active {
                        border: 1px solid $primary-color;
                    }

                    p {
                        font-size: 14px;
                        margin: 0;
                    }

                    h5 {
                        font-size: 20px;
                        font-weight: 700;
                        color: $primary-color;
                        margin: 0;
                        margin-bottom: 20px;
                    }

                    .duration_block {
                        padding: 0;

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: $text-color;
                            margin: 0;
                        }

                        .form_block {
                            .radio_button_block {
                                .css-1hbvpl3-MuiSvgIcon-root {
                                    width: 18px;
                                    height: 18px;
                                    fill: $primary-color;
                                }

                                .css-11zohuh-MuiSvgIcon-root {
                                    font-size: 18px;
                                    fill: $primary-color;
                                }

                                label {
                                    span {
                                        font-size: 14px !important;
                                        font-family: "Atak Regular";
                                    }
                                }
                            }
                        }
                    }

                    .checkbox_block {
                        padding-bottom: 10px;

                        .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
                            padding-left: 0;
                        }

                        .css-i4bv87-MuiSvgIcon-root {
                            width: 22px;
                            height: 22px;
                            margin-left: -2px;
                        }

                        .css-ahj2mt-MuiTypography-root {
                            font-size: 14px;
                            font-family: "Atak Regular";
                        }
                    }

                    .upgrade_credit_block {
                        padding-bottom: 15px;

                        h6 {
                            font-size: 16px;
                            font-weight: 700;
                            color: $primary-color;
                            margin: 0;
                            margin-bottom: 10px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 400;
                            color: $text-color;
                            margin: 0;
                            display: inline-block;
                        }

                        span {
                            font-size: 12px;
                            font-weight: 400;
                            color: $white-color;
                            background-color: $primary-color;
                            padding: 3px 6px;
                            border-radius: 5px;
                            display: inline-block;
                            margin-left: 5px;
                        }
                    }
                }
            }

            .type_block {
                padding: 0 20px;

                &.border_right {
                    border-right: 1px solid $border-color;
                }

                &.margint {
                    margin-bottom: 30px;
                }

                .types {
                    text-align: center;
                    padding: 15px;
                    background-color: $white-color;
                    border-radius: 10px;
                    display: block;

                    h6 {
                        font-size: 16px;
                        color: $primary-color;
                        margin: 0;
                        margin-top: 10px;
                    }

                    &.active {
                        border: 1px solid $primary-color;
                    }

                    &.deactive {
                        border: 1px solid $white-color;
                    }
                }
            }

            .amenities_list {
                margin-bottom: 0;

                .css-ljcuss-MuiButtonBase-root-MuiChip-root{
                    background-color: $primary-color;
                }

                &.requirements {
                    margin: 0 20px 40px 20px;

                    .css-ljcuss-MuiButtonBase-root-MuiChip-root{
                        background-color: $primary-color;
                    }
                }
            }

            .form_block {
                &.requirement {
                    margin: 0 10px;
                }
            }

            .form-group {
                margin-bottom: 15px;

                .form-control {
                    background-color: $white-color;
                    border: 1px solid $border-color;
                    padding: 14px 20px;
                    height: 50px;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 7px;
                    color: $text-color;

                    &.border_temp {
                        padding: 0;

                        .MuiSelect-select {
                            padding: 16px 20px;
                            font-style: normal;
                        }

                        fieldset {
                            border-color: transparent;
                        }

                        .select_text {
                            font-style: normal;
                        }

                        svg {
                            right: 20px;
                            color: $text-color;
                        }
                    }

                    &.right_space {
                        padding-right: 70px;
                    }

                    &::placeholder {
                        color: $text-color;
                    }
                }

                .form-line {
                    border: none;
                    position: relative;

                    &::after {
                        border: none;
                    }

                    .form_control_title {
                        position: absolute;
                        right: 20px;
                        top: 17px;
                        font-size: 12px;
                        color: $text-color;
                        opacity: 0.7;
                        background-color: $white-color;
                    }
                }

                .btn-default {
                    padding: 0;
                    font-size: 14px;
                    color: $text-color;
                    border: none;
                    background-color: transparent !important;
                }

                .bootstrap-select {
                    border: 1px solid $border-color !important;

                    &.btn-group {
                        .dropdown-toggle {
                            .filter-option {
                                top: 0 !important;
                                margin-left: 0;
                            }

                            .caret {
                                margin-top: 2px;
                            }
                        }
                    }
                }
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: $text-color;
                line-height: 24px;
                margin: 0;
                margin-bottom: 20px;
            }

            .form_block {
                .row {
                    margin-left: -5px;
                    margin-right: -5px;

                    .col-md-3,
                    .col-md-6,
                    .col-md-12 {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }

                .form-group {
                    margin-bottom: 15px;

                    .form-control {
                        background-color: $white-color;
                        border: 1px solid $border-color;
                        padding: 14px 20px;
                        height: 50px;
                        font-size: 14px;
                        font-weight: 400;
                        border-radius: 7px;
                        color: $text-color;

                        &.border_temp {
                            padding: 0;

                            .MuiSelect-select {
                                padding: 16px 20px;
                                font-style: normal;
                            }

                            fieldset {
                                border-color: transparent;
                            }

                            .select_text {
                                font-style: normal;
                            }

                            svg {
                                right: 20px;
                                color: $text-color;
                            }
                        }

                        &.right_space {
                            padding-right: 70px;
                        }

                        &::placeholder {
                            color: $text-color;
                        }
                    }

                    .form-line {
                        border: none;
                        position: relative;

                        &::after {
                            border: none;
                        }

                        .form_control_title {
                            position: absolute;
                            right: 20px;
                            top: 17px;
                            font-size: 12px;
                            color: $text-color;
                            opacity: 0.7;
                            background-color: $white-color;
                        }
                    }

                    .btn-default {
                        padding: 0;
                        font-size: 14px;
                        color: $text-color;
                        border: none;
                        background-color: transparent !important;
                    }

                    .bootstrap-select {
                        border: 1px solid $border-color !important;

                        &.btn-group {
                            .dropdown-toggle {
                                .filter-option {
                                    top: 0 !important;
                                    margin-left: 0;
                                }

                                .caret {
                                    margin-top: 2px;
                                }
                            }
                        }
                    }
                }
            }

            h4 {
                font-size: 16px;
                font-weight: 700;
                color: $primary-color;
                margin: 0;
                margin-top: 5px;
                margin-bottom: 20px;
            }

            .plans_detail {
                background-color: $grey-color;
                padding: 15px;

                .plans_block {
                    h6 {
                        font-size: 14px;
                        font-weight: 400;
                        color: $text-color;
                        padding-bottom: 10px;
                        margin: 0;
                        margin-bottom: 10px;
                        border-bottom: 1px solid $border-color;
                        font-family: "Atak Regular";
                        line-height: normal;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 400;
                        color: $text-color;
                        margin: 0;
                        opacity: 0.7;
                        margin-bottom: 7px;
                        line-height: normal;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .navbar.common {
        background-color: $white-color;
        min-height: 85px;

        .navbar-nav>li>a {
            background-color: transparent !important;
        }

        .label-count {
            color: $white-color;
            background-color: $text-color;
        }

        .navbar-brand {
            min-height: 65px;
            padding: 0;
        }

        .navbar-right {
            margin-top: 10px !important;
        }

        .user-info {
            .info-container {
                cursor: default;
                display: block;

                .user-helper-dropdown {
                    position: absolute;
                    right: -3px;
                    top: 25px;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    -ms-box-shadow: none;
                    box-shadow: none;
                    cursor: pointer;

                    .dropdown-menu {
                        margin-top: 10px !important;
                        padding: 10px;
                        border-radius: 10px;

                        .name.custom_name,
                        .email.custom_email {
                            padding: 5px 15px;
                        }

                        & {
                            ul.menu {
                                list-style: none;
                            }
                        }

                        &>li>a:hover,
                        &>li>a:focus {
                            color: $primary-color;
                        }

                        &>li>a {
                            color: $text-color;
                        }
                    }
                }
            }

            .user_image {
                border-radius: 50%;
                margin-right: 5px;
                margin-left: 15px;
                margin-top: 7px;
            }
        }
    }

    .tab_block {
        padding: 30px;
        background-color: $white-color;
        border-radius: 20px;
        
        .css-1aquho2-MuiTabs-indicator{
            background-color: $primary-color;
        }

        .tab-nav-right {
            border-bottom: 1px solid $border-color;
            color: $text-color;

            .tabs_title {
                font-size: 14px;
                font-weight: 400;
                color: $text-color;
                text-transform: capitalize;
                opacity: 0.7;
                padding: 10px 20px 15px 20px;
                font-family: "Helvetica Now Display Regular";
                letter-spacing: 0.2px;

                &.Mui-selected {
                    opacity: 1;
                }
            }
        }
    }

    .tab_content_block {
        padding: 30px 0 0 0;

        &.setting {
            padding: 0;
        }

        .row {
            margin-left: -5px;
            margin-right: -5px;

            .col-sm-9,
            .col-sm-3,
            .col-md-4,
            .col-md-8 {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .rightside_block {
            background-color: $white-color;
            border-radius: 20px;
            padding: 30px;

            h4 {
                font-size: 16px;
                color: $primary-color;
                margin: 0;
                margin-bottom: 30px;
            }

            .update_block {
                margin-bottom: 30px;

                p {
                    font-size: 14px;
                    color: $text-color;
                    margin: 0;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .form-group {
                margin-bottom: 20px;

                .form-control {
                    background-color: $white-color;
                    border: 1px solid $border-color;
                    padding: 16px 20px;
                    height: auto;
                    font-size: 14px;
                    font-weight: 400;
                    border-radius: 7px;
                    color: $text-color;
                    height: 50px;
                    line-height: normal;

                    .MuiSelect-select {
                        padding: 0;
                        font-style: normal;
                    }

                    fieldset {
                        border-color: transparent;
                    }

                    .select_text {
                        font-style: normal;
                    }

                    svg {
                        right: 20px;
                        color: $text-color;
                    }

                    &.right_space {
                        padding-right: 70px;
                    }

                    &::placeholder {
                        color: $text-color;
                    }
                }

                .form-line {
                    border: none;
                    position: relative;

                    &::after {
                        border: none;
                    }

                    .form_control_title {
                        position: absolute;
                        right: 20px;
                        top: 17px;
                        font-size: 12px;
                        color: $text-color;
                        opacity: 0.7;
                        background-color: $white-color;
                    }

                    .custom-file {
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        height: 50px;
                        margin-bottom: 0;
                        font-size: 14px;
                        font-weight: 400 !important;

                        .custom-file-input {
                            position: relative;
                            z-index: 2;
                            width: 100%;
                            height: 35px;
                            margin: 0;
                            opacity: 0;
                        }

                        .custom-file-label {
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            z-index: 1;
                            height: auto;
                            padding: 20px;
                            line-height: 1.5;
                            color: #495057;
                            background-color: $white-color;
                            border: 1px solid $border-color;
                            border-radius: 7px;
                            font-size: 14px;
                            font-weight: 400 !important;
                        }

                        .custom-file-input:lang(en)~.custom-file-label::after {
                            content: "Browse";
                        }

                        .custom-file-label::after {
                            position: absolute;
                            right: 20px;
                            top: 14px;
                            z-index: 3;
                            display: block;
                            padding: 5px 10px;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 1.5;
                            color: $white-color;
                            content: "Browse";
                            background-color: $primary-color;
                            border-radius: 20px;
                        }
                    }
                }

                .btn-default {
                    padding: 0;
                    font-size: 14px;
                    color: $text-color;
                    border: none;
                    background-color: transparent !important;
                }

                .bootstrap-select {
                    border: 1px solid $border-color !important;

                    &.btn-group {
                        .dropdown-toggle {
                            .filter-option {
                                top: 0 !important;
                                margin-left: 0;
                            }

                            .caret {
                                margin-top: 2px;
                            }
                        }
                    }
                }
            }

            .availability_block {
                margin-bottom: 5px;

                h6 {
                    font-size: 16px;
                    font-weight: 400;
                    color: $text-color;
                    font-family: "Atak Regular";
                    margin: 0;
                    margin-bottom: 5px;
                }

                [type="checkbox"].filled-in:checked.chk-col-pink+label:after {
                    background-color: $secondary-color;
                    border: 2px solid $secondary-color;
                }

                [type="checkbox"]+label {
                    font-size: 14px;
                    font-weight: 400;
                    color: $text-color;
                }
            }

            .listing_status_block {
                padding: 10px;
                border: 1px solid $border-color;
                border-radius: 7px;
                margin-bottom: 30px;

                h5 {
                    font-size: 16px;
                    font-weight: 500;
                    font-family: "Atak Medium";
                    color: $text-color;
                    margin: 0;
                    margin-bottom: 15px;
                }

                .listing_block {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    p {
                        font-size: 14px;
                        color: $text-color;
                        margin: auto 0;
                    }

                    a {
                        background-color: $primary-color;
                        color: $white-color;
                        padding: 4px 8px;
                        border-radius: 3px;

                        &.green {
                            background-color: $secondary-color;
                        }

                        &.blue {
                            background-color: $primary-color;
                        }
                    }
                }

                .table_heading{
                    .switch_bar{
                        .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
                            color: $primary-color;
                        }

                        .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled{
                            color: $primary-one-color;
                        }

                        .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
                            background-color: $primary-color;
                        }
                    } 
                }

                span {
                    font-size: 10px;
                    font-style: italic;
                }
            }

            .quality_score_block {
                margin-bottom: 30px;
                padding: 10px;
                border: 1px solid $border-color;
                border-radius: 7px;

                span {
                    font-size: 10px;
                    font-weight: 400;
                    color: $text-color;
                    opacity: 0.7;
                    margin-bottom: 15px;
                    display: block;
                }

                .quality_score_title {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;

                    h5 {
                        font-size: 16px;
                        font-weight: 500;
                        font-family: "Atak Medium";
                        color: $text-color;
                        margin: 0;
                        margin: auto 0;
                    }

                    .quality_block {
                        display: flex;
                        justify-content: center;

                        .progress_bar {
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            border: 3px solid #5EAC18;
                            margin-right: 5px;
                            display: inline-blockss;
                        }

                        .opac {
                            font-size: 10px;
                            font-weight: 400;
                            color: $text-color;
                            opacity: 0.7;
                            margin-top: 8px;
                        }
                    }
                }

                .quality_detail {
                    .quality_tag {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        h6 {
                            font-size: 12px;
                            font-weight: 400;
                            color: $text-color;
                            margin: 0;
                            font-family: "Atak Regular";
                        }

                        p {
                            font-size: 12px;
                            font-weight: 400;
                            color: $text-color;
                            margin: 0;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .file-drop-area {
            position: relative;
            padding: 25px;
            border: 2px dashed $primary-color;
            border-radius: 20px;
            transition: 0.2s;
            text-align: center;

            &.images {
                border: none;
                background-color: $grey-color;
                border-radius: 0;
                padding: 0;
                height: 100%;
                min-height: 187px;

                figure {
                    text-align: center;
                    display: block;

                    img {
                        margin-bottom: 0;
                        width: auto;
                    }
                }

                .choose-file-button {
                    padding: 0;
                    background-color: transparent;
                    color: $text-color;
                    font-size: 16px;
                    font-weight: 400;
                    opacity: 0.7;
                    text-transform: capitalize;
                }
            }

            img {
                margin-bottom: 20px;
            }

            .show-message {
                display: block;
                font-size: 20px;
                font-weight: 700;
                color: $primary-color;
                margin-bottom: 20px;
                font-family: "Helvetica Now Display Bold";
            }

            .choose-file-button {
                flex-shrink: 0;
                background-color: $primary-color;
                color: $white-color;
                font-size: 14px;
                font-weight: 500;
                font-family: "Helvetica Now Display Medium";
                border-radius: 30px;
                padding: 15px 18px;
                display: inline-block;
                text-transform: uppercase;
            }

            .file-message {
                font-size: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                margin-bottom: 20px;
            }

            .file-input {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                cursor: pointer;
                opacity: 0;

            }
        }

        .panel-group {
            margin-bottom: 30px;
            padding: 30px;
            background-color: $white-color;
            border-radius: 20px;

            .panel {
                box-shadow: none;
                border: none;

                .button_block {
                    padding-top: 20px;
                    text-align: right;

                    .links {
                        margin-left: 15px;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0.2px;
                        padding: 14px 18px;
                        display: inline-block;
                        border: 1px solid $primary-color;
                        background-color: $white-color;
                        border-radius: 50px;
                        text-transform: uppercase;

                        &:hover {
                            background-color: $primary-color;
                            color: $white-color;
                        }
                    }
                }

                .panel-heading {
                    a {
                        padding: 0;
                        display: flex;
                        justify-content: space-between;

                        .credit_balance_block {
                            text-align: right;
                            margin-bottom: 15px;

                            .links {
                                margin-left: 15px;
                                font-size: 14px;
                                font-weight: 500;
                                letter-spacing: 0.2px;
                                padding: 14px 18px;
                                display: inline-block;
                                border: 1px solid $primary-color;
                                background-color: $white-color;
                                border-radius: 50px;
                                text-transform: uppercase;

                                &.export {
                                    padding: 13px 18px;
                                }

                                &:hover {
                                    background-color: $primary-color;
                                    color: $white-color;
                                }
                            }

                            .balance_block {
                                border: 1px solid $border-color;
                                border-radius: 5px;
                                display: inline-block;

                                p {
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: $text-color;
                                    margin: 0;
                                    display: inline-block;
                                    margin: 10px 0 10px 20px;
                                    padding-right: 20px;

                                    span {
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: $text-color;
                                        margin: 0;
                                    }

                                    img {
                                        margin-left: 10px;
                                    }
                                }

                                button {
                                    padding: 9px 15px;
                                    border: none;
                                    border-left: 1px solid $border-color;
                                    border-radius: 0 6px 6px 0;
                                }
                            }
                        }

                        span {
                            font-size: 25px;
                            font-weight: 700;
                            color: $primary-color;
                            font-family: "Helvetica Now Display Bold";
                        }

                        i {
                            width: 28px;
                            height: 28px;
                            display: inline-block;
                            background-color: $primary-color;
                            color: $white-color;
                            font-style: normal;
                            font-size: 16px;
                            font-family: uicons-bold-rounded !important;
                            font-style: normal;
                            font-weight: normal !important;
                            font-variant: normal;
                            text-transform: none;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            border-radius: 50%;
                            position: relative;
                        }

                        i.openIcon:after {
                            content: "\f737";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        i.closeIcon:after {
                            content: "\f813";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }

            .panel-body {
                border-top: none !important;
                padding: 0;
                padding-top: 30px;

                .card {
                    box-shadow: none;
                    margin-bottom: 0;

                    .shortlist_dropdown {
                        display: flex;
                        justify-content: space-between;
                    }

                    .rating_star {
                        .style-module_fillIcons__6---A {
                            color: $secondary-color !important;
                        }
                    }

                    .form-group {
                        &.checkbox {
                            span {
                                svg {
                                    height: 25px;
                                    width: 25px;
                                }
                            }
                        }
                    }

                    .props_title {
                        font-size: 16px;
                        font-weight: 400;
                        color: $text-color;
                        margin: 0;
                        margin-bottom: 30px;
                    }

                    .props_detail {
                        border: 1px solid $primary-color;
                        padding: 20px;
                        border-radius: 10px;
                        position: relative;
                        margin-bottom: 20px;

                        .like_property {
                            position: absolute;
                            top: 15px;
                            right: 15px;
                            width: 40px;
                            height: 40px;
                            display: inline-block;
                            background: $white-color;
                            color: $primary-color;
                            border-radius: 5px;
                            text-align: center;

                            .like_icon {
                                padding: 5px;

                            }
                        }

                        .property_list_detail {
                            margin-left: 15px;

                            h2 {
                                margin-bottom: 15px;
                            }

                            p.addressing {
                                font-size: 14px;
                                font-weight: 400;
                                color: $text-color;
                                margin: 0;
                                margin-bottom: 15px;

                                img {
                                    margin-right: 10px;
                                }
                            }

                            .amenities_details {
                                margin-bottom: 15px;
                                padding: 0;
                                border: none;

                                p.status {
                                    margin: 0;
                                }

                                .amenities_detail_block {
                                    padding: 0 30px;
                                    border-right: 1px solid $border-color;
                                    display: inline-block;

                                    &.last {
                                        padding: 0;
                                        padding-left: 20px;
                                    }

                                    &:first-child {
                                        padding-left: 0;
                                    }

                                    &:last-child {
                                        border-right: none;
                                    }

                                    img {
                                        margin-right: 6px;
                                    }

                                    span {
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: $text-color;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .props_detail_overlay {
                                visibility: visible;
                            }
                        }

                        .props_detail_overlay {
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            background: $black-overlay;
                            border: 1px solid transparent;
                            display: block;
                            padding: 30px;
                            visibility: hidden;
                            -webkit-transition: all .35s ease-in-out;
                            -moz-transition: all .35s ease-in-out;
                            -o-transition: all .35s ease-in-out;
                            -ms-transition: all .35s ease-in-out;
                            transition: all .35s ease-in-out;
                            border-radius: 10px;

                            .form-group {

                                .form-control {
                                    width: auto;
                                }
                            }
                        }
                    }

                    .requirement_block {
                        display: flex;
                        justify-content: space-between;

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: $text-color;
                            margin: 0;
                            margin: auto 0;

                            img {
                                margin-right: 4px;
                            }

                            &:first-child {
                                margin: auto 0;
                            }
                        }

                        a {
                            font-size: 14px;
                            font-weight: 400;
                            color: $primary-color;
                            border: 1px solid $primary-color;
                            padding: 14px 18px;
                            border-radius: 30px;
                            display: inline-block;
                            text-transform: uppercase;

                            &:hover {
                                background-color: $primary-color;
                                color: $white-color;
                            }
                        }
                    }

                    .amenities_block {
                        p {
                            font-size: 16px;
                            margin: 0;
                            margin-bottom: 30px;

                        }

                        a {
                            border: 1px solid $primary-color;
                            color: $primary-color;
                            font-size: 14px;
                            background-color: $white-color;
                            border-radius: 3px;
                            padding: 6px 15px;
                            display: inline-block;
                        }

                        span {
                            border: 1px solid $primary-color;
                            color: $white-color;
                            font-size: 14px;
                            font-weight: 400;
                            background-color: $primary-color;
                            border-radius: 3px;
                            padding: 6px 15px;
                            display: inline-block;
                            margin-right: 5px;
                            margin-bottom: 5px;
                        }
                    }

                    .type_block {
                        padding: 20px 50px;

                        &.border_right {
                            border-right: 1px solid $border-color;
                        }

                        &.margint {
                            margin-bottom: 30px;
                        }

                        .types {
                            text-align: center;
                            padding: 15px;
                            background-color: $white-color;
                            border-radius: 10px;
                            display: block;

                            h6 {
                                font-size: 16px;
                                color: $primary-color;
                                margin: 0;
                                margin-top: 10px;
                            }

                            &.active {
                                border: 1px solid $primary-color;
                            }

                            &.deactive {
                                border: 1px solid $white-color;
                            }
                        }
                    }

                    .main_class {
                        padding: 0;

                        .form-group {
                            margin-bottom: 20px;
                            position: relative;

                            .form-line {
                                border: none;
                                position: relative;

                                .custom-file {
                                    position: relative;
                                    display: inline-block;
                                    width: 100%;
                                    height: 64px;
                                    margin-bottom: 0;
                                    font-size: 14px;
                                    font-weight: 400 !important;

                                    .custom-file-input {
                                        position: relative;
                                        z-index: 2;
                                        width: 100%;
                                        height: 35px;
                                        margin: 0;
                                        opacity: 0;
                                    }

                                    .custom-file-label {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        left: 0;
                                        z-index: 1;
                                        height: auto;
                                        padding: 20px;
                                        line-height: 1.5;
                                        color: #495057;
                                        background-color: $white-color;
                                        border: 1px solid $border-color;
                                        border-radius: 7px;
                                        font-size: 14px;
                                        font-weight: 400 !important;
                                    }

                                    .custom-file-input:lang(en)~.custom-file-label::after {
                                        content: "Browse";
                                    }

                                    .custom-file-label::after {
                                        position: absolute;
                                        right: 20px;
                                        top: 14px;
                                        z-index: 3;
                                        display: block;
                                        padding: 5px 10px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 1.5;
                                        color: $white-color;
                                        content: "Browse";
                                        background-color: $primary-color;
                                        border-radius: 20px;
                                    }
                                }
                            }
                        }
                    }

                    p {
                        margin: 30px 0 15px 0;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .form_block {
                        .row {
                            margin-left: -5px;
                            margin-right: -5px;

                            .col-sm-6,
                            .col-sm-12 {
                                padding-left: 5px;
                                padding-right: 5px;
                            }
                        }

                        .form-group {
                            margin-bottom: 20px;
                            position: relative;

                            .react-tel-input{

                                &.contact_form_control{

                                    .form-control{
                                        width: 100%;
                                        padding-left: 50px;   
                                    }
                                }
            
                                .flag-dropdown{
                                    background-color: $white-color;
                                    border-radius: 7px 0 0 7px;

                                    .selected-flag{
                                        &:hover{
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }

                            .auto_complete_form {
                                display: block;
                            }

                            .form-control {
                                background-color: $white-color;
                                border: 1px solid $border-color;
                                padding: 16px 20px;
                                height: auto;
                                font-size: 14px;
                                font-weight: 400;
                                border-radius: 7px;
                                color: $text-color;
                                height: 50px;

                                &.auto_complete {
                                    padding: 0 7px;

                                    input {
                                        font-size: 14px;
                                        margin-bottom: 0;
                                        color: $text-color;
                                        font-weight: 400;
                                        margin-top: -3px;

                                        input::placeholder {
                                            color: $text-color;
                                        }

                                        input::-webkite-inline-placeholder {
                                            color: $text-color;
                                        }
                                    }

                                    div.MuiAutocomplete-root.MuiPopper-root.MuiAutocomplete-popperDisable.portal.css-1rndd9j-MuiPopper-root-MuiAutocomplete-popper {
                                        font-size: 14px;
                                        margin-bottom: 0;
                                        color: $text-color;
                                        font-weight: 400;
                                    }

                                    .css-1q60rmi-MuiAutocomplete-endAdornment {
                                        top: calc(50% - 10px);
                                    }
                                }

                                .MuiSelect-select {
                                    padding: 0;
                                    font-style: normal;
                                }

                                fieldset {
                                    border-color: transparent;
                                }

                                .select_text {
                                    font-style: normal;
                                }

                                svg {
                                    right: 20px;
                                    color: $text-color;
                                }

                                &.right_space {
                                    padding-right: 70px;
                                }

                                &::placeholder {
                                    color: $text-color;
                                }
                            }

                            textarea.form-control {
                                height: auto;
                            }

                            .form-line {
                                border: none;
                                position: relative;

                                &::after {
                                    border: none;
                                }

                                .form_control_title {
                                    position: absolute;
                                    right: 20px;
                                    top: 17px;
                                    font-size: 12px;
                                    color: $text-color;
                                    opacity: 0.7;
                                    background-color: $white-color;
                                }

                                .custom-file {
                                    position: relative;
                                    display: inline-block;
                                    width: 100%;
                                    height: 64px;
                                    margin-bottom: 0;
                                    font-size: 14px;
                                    font-weight: 400 !important;

                                    .custom-file-input {
                                        position: relative;
                                        z-index: 2;
                                        width: 100%;
                                        height: 35px;
                                        margin: 0;
                                        opacity: 0;
                                    }

                                    .custom-file-label {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        left: 0;
                                        z-index: 1;
                                        height: auto;
                                        padding: 20px;
                                        line-height: 1.5;
                                        color: #495057;
                                        background-color: $white-color;
                                        border: 1px solid $border-color;
                                        border-radius: 7px;
                                        font-size: 14px;
                                        font-weight: 400 !important;
                                    }

                                    .custom-file-input:lang(en)~.custom-file-label::after {
                                        content: "Browse";
                                    }

                                    .custom-file-label::after {
                                        position: absolute;
                                        right: 20px;
                                        top: 14px;
                                        z-index: 3;
                                        display: block;
                                        padding: 5px 10px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 1.5;
                                        color: $white-color;
                                        content: "Browse";
                                        background-color: $primary-color;
                                        border-radius: 20px;
                                    }
                                }
                            }

                            .btn-default {
                                padding: 0;
                                font-size: 14px;
                                color: $text-color;
                                border: none;
                                background-color: transparent !important;
                            }

                            .bootstrap-select {
                                border: 1px solid $border-color !important;

                                &.btn-group {
                                    .dropdown-toggle {
                                        .filter-option {
                                            top: 0 !important;
                                            margin-left: 0;
                                        }

                                        .caret {
                                            margin-top: 2px;
                                        }
                                    }
                                }
                            }
                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: $text-color;
                            line-height: 24px;
                            margin: 0 0 20px 0;

                            .notes {
                                font-size: 16px;
                                font-weight: 500;
                                font-family: "Atak Medium";
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }

    .sidebar {
        top: 90px;
        left: 0;
        width: 110px;
        overflow: inherit;
        background-color: #FFF;

        &.top_leftsidebar {
            top: 0;
            height: 100%;
        }

        .menu {
            height: auto;
            overflow: inherit;

            .list {
                li {
                    border-bottom: 1px solid $border-color;

                    &:hover {
                        background-color: $white-color !important;
                    }

                    a {
                        padding: 10px;
                        text-align: center;
                        flex-direction: column;
                        justify-content: center;
                        transition: all ease-in-out 0.5s;
                        background-color: $white-color;
                    }
                }

                li.active> :first-child {
                    span {
                        font-weight: 400;
                    }
                }

                .custom-menu-item {
                    position: relative;

                    &.active {
                        a {
                            background-color: $primary-light-color;
                        }

                        &:first-child {
                            span {
                                font-weight: 400;
                            }
                        }
                    }

                    a {
                        background-color: $white-color;

                        i {
                            margin-bottom: 2px;

                            img {
                                width: 35px;
                            }
                        }

                        span {
                            font-size: 13px;
                            font-weight: 400;
                            color: $text-color;
                            margin: 0;
                        }
                    }

                    &:hover {
                        .custom-sub-menu {
                            transform: translate3d(0%, 0px, 0px);
                            -webkit-transform: translate3d(0%, 0px, 0px);
                            -ms-transform: translate3d(0%, 0px, 0px);
                        }
                    }

                    .custom-sub-menu {
                        top: 0;
                        left: 100%;
                        z-index: -1;
                        width: 300px;
                        padding: 15px 0;
                        position: absolute;
                        background: $white-color;
                        transform: translate3d(-100%, 0px, 0px);
                        -ms-transform: translate3d(-100%, 0px, 0px);
                        -webkit-transform: translate3d(-100%, 0px, 0px);
                        transition: all 0.3s ease 0.3s;
                        -webkit-transition: all 0.3s ease 0.3s;
                        border: 1px solid $border-color;

                        h4 {
                            gap: 0.4rem;
                            display: flex;
                            padding: 0 15px;
                            font-size: 16px;
                            font-weight: 500;
                            margin: 0 0 5px 0;
                            align-items: center;

                            img {
                                width: 25px;
                                margin-top: 0;
                                margin-bottom: 5px;
                                margin-right: 5px;
                            }
                        }

                        ul {
                            padding: 0 15px;
                            list-style-type: none;

                            li {
                                padding: 2px 0;

                                a {
                                    text-align: left;
                                    color: $text-color;

                                    &:hover {
                                        background-color: $secondary-color;
                                    }
                                }

                                &:last-child {
                                    padding: 2px 0;
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    section.content {
        margin: 110px 30px 0 140px;

        &.full_page {
            margin: 0;
        }
    }

    .block-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        h2 {
            font-size: 20px;
            font-weight: 700;
            color: $text-color;
            margin: auto 0 !important;
        }

        .credit_name {
            display: inline-block;

            p {
                font-size: 16px;
                font-weight: 400;
                color: $text-color;
                margin: 0;
                display: inline-block;

                &.slace {
                    margin: 0 10px;
                }

                &.blue {
                    color: $primary-color;
                }
            }
        }

        .link_block {
            a.more_link {
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: $text-color;
                border: 1px solid $primary-color;
                background-color: $white-color;
                border-radius: 50px;
                padding: 14px 18px;
                display: inline-block;
                margin-left: 15px;

                &:hover {
                    background-color: $primary-color;
                    color: $white-color;
                }
            }
        }
    }

    .page_heading {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        background-color: $white-color;
        border-radius: 20px;
        margin-bottom: 30px;

        h3 {
            font-size: 25px;
            font-weight: 700;
            color: $primary-color;
            margin: auto 0;
        }

        a {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: block;
            background-color: $primary-light-color;
            padding: 15px;

            img {
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }



    .main_class {
        padding: 30px;
        background-color: $white-color;
        margin-bottom: 30px;
        border-radius: 20px;

        .button_block {
            padding: 0;
        }

        .title_heading {
            font-size: 25px;
            font-weight: 700;
            color: $primary-color;
            font-family: "Helvetica Now Display Bold";
            margin: 0;
            margin-bottom: 30px;
        }

        .pagination {
            button {
                font-size: 14px !important;
                color: $text-color;
            }
        }

        .links {
            margin-left: 15px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.2px;
            padding: 14px 18px;
            display: inline-block;
            border: 1px solid $primary-color;
            background-color: $white-color;
            border-radius: 50px;
            text-transform: uppercase;

            &.export {
                padding: 13px 18px;
            }

            &:hover {
                background-color: $primary-color;
                color: $white-color;
            }
        }

        .requirement_set_block {
            padding: 25px;
            border: 2px dashed $primary-color;
            border-radius: 20px;
            transition: 0.2s;
            text-align: center;

            img {
                margin-bottom: 20px;
            }

            .requirement_set_title {
                display: block;
                font-size: 20px;
                font-weight: 700;
                color: $primary-color;
                margin-bottom: 20px;
                font-family: "Helvetica Now Display Bold";
            }

            .requirement_set_description {
                font-size: 16px;
                display: block;
                margin-bottom: 20px;
                color: $text-color;
            }

            .links {
                background-color: $primary-color;
                color: $white-color;
                font-size: 14px;
                font-weight: 500;
                font-family: "Helvetica Now Display Medium";
                border-radius: 30px;
                padding: 15px 18px;
                display: inline-block;
                text-transform: uppercase;
            }
        }

        .credit_balance_block {
            text-align: right;
            margin-bottom: 15px;

            .balance_block {
                border: 1px solid $border-color;
                border-radius: 5px;
                display: inline-block;

                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: $text-color;
                    margin: 0;
                    display: inline-block;
                    margin: 10px 0 10px 20px;
                    padding-right: 20px;

                    &.balanced {
                        border-right: 1px solid $border-color;
                    }

                    img {
                        margin-left: 10px;
                    }
                }

                button {
                    padding: 9px 15px;
                    border: none;
                    border-left: 1px solid $border-color;
                    border-radius: 0 6px 6px 0;
                }
            }
        }

        .right_block {
            text-align: right;
        }

        .property_image_block {
            padding: 0;
            list-style: none;
            margin-top: 20px;
            margin-bottom: 10px;

            .row {
                margin-left: -5px;
                margin-right: -5px;

                .col-md-3,
                .col-sm-4,
                .col-xs-6 {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            .property_image {
                position: relative;
                min-height: 1px;
                width: 100%;
                display: inline-block;

                &.default {
                    position: relative;

                    .default_block {
                        position: absolute;
                        top: 15px;
                        right: 15px;

                        .default_image {
                            padding: 5px 10px;
                            background-color: $white-color;
                            text-align: center;
                            border-radius: 5px;
                            margin-left: 5px;

                            img {
                                width: 25px;
                                height: 40px;
                            }
                        }

                        .delete_image {
                            width: 40px;
                            height: 40px;
                            background-color: $white-color;
                            text-align: center;
                            border-radius: 5px;
                            display: inline-block;
                            margin-left: 5px;

                            img {
                                width: 25px;
                                height: 40px;
                            }

                            &:hover {
                                background-color: $red-color;

                                img {
                                    filter: brightness(0) invert(1);
                                }
                            }
                        }
                    }
                }

                img {
                    max-width: 100%;
                    width: 100%;
                    height: 205px;
                }

            }
        }

        p.note {
            font-size: 14px;
            font-weight: 400;
            color: $text-color;
            line-height: 24px;
            margin: 20px 0;

            .notes {
                font-size: 16px;
                font-weight: 500;
                font-family: "Atak Medium";
                color: $primary-color;
            }
        }

        .note_block {
            .note_person {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                .note_person_image {
                    display: flex;
                    justify-content: space-between;

                    img {
                        border-radius: 5px;
                        margin-right: 20px;
                        display: inline-block;
                    }

                    .person_detail {
                        margin: auto 0;

                        h5 {
                            font-size: 20px;
                            font-weight: 700;
                            color: $primary-color;
                            font-family: "Helvetica Now Display Bold";
                            margin: 0;
                            margin-bottom: 5px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 400;
                            color: $text-color;
                            opacity: 0.7;
                            margin: 0;
                            line-height: 24px;
                        }
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        color: $text-color;
                        line-height: 24px;
                    }
                }

                .note_time {
                    margin: auto 0;
                }
            }
        }

        .button_block {
            padding-top: 20px;
            text-align: right;

            .links {
                margin-left: 15px;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.2px;
                padding: 14px 18px;
                display: inline-block;
                border: 1px solid $primary-color;
                background-color: $white-color;
                border-radius: 50px;
                text-transform: uppercase;

                &:hover {
                    background-color: $primary-color;
                    color: $white-color;
                }
            }
        }

        .form_block {
            &.outline_form {
                .form-group {
                    margin-bottom: 20px;
                    position: relative;

                    .form-line {
                        border: none;
                        position: relative;

                        .form-control {
                            background-color: $white-color;
                            border: 1px solid $border-color;
                            padding: 16px 20px;
                            height: auto;
                            font-size: 14px;
                            font-weight: 400;
                            border-radius: 7px;
                            color: $text-color;
                            height: 50px;
                        }
                    }
                }
            }

            .range_slider_block {
                height: 54px;
                margin-bottom: 10px;
                padding: 0 10px;

                h6 {
                    font-size: 12px;
                    font-weight: 400;
                    color: $text-color;
                    margin: 0;
                    margin-bottom: 5px;
                    font-family: "Atak Regular";
                }

                .range_slider {
                    padding: 8px 0;
                    margin-bottom: -5px;
                }

                .css-eg0mwd-MuiSlider-thumb {
                    width: 14px;
                    height: 14px;
                }

                .css-eg0mwd-MuiSlider-thumb::after {
                    height: 30px;
                    width: 30px;
                }

                .left {
                    font-size: 10px;
                    font-weight: 400;
                    color: $text-color;
                    margin: 0;
                }

                .right {
                    font-size: 10px;
                    font-weight: 400;
                    color: $text-color;
                    margin: 0;
                }
            }

            .row {
                margin-left: -5px;
                margin-right: -5px;

                .col-md-3,
                .col-sm-4,
                .col-xs-6,
                .col-sm-6,
                .col-sm-12 {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            .form-group {
                margin-bottom: 10px;

                &.notes {
                    margin-bottom: 0;

                    .form-control {
                        font-size: 16px;
                        font-weight: 400;
                        color: $text-color;
                        padding: 0;
                        height: auto;
                        background-color: $white-color;

                        &::placeholder {
                            color: $text-color;
                            opacity: 1;
                        }
                    }
                }

                .form-control {
                    background-color: $grey-color;
                    border: none;
                    padding: 16px 20px;
                    height: auto;
                    font-size: 16px;
                    border-radius: 7px;
                    color: $text-color;
                    height: 54px;
                    text-align: left;
                    font-style: normal;

                    &.auto_complete {
                        padding: 0;

                        .css-igs3ac {
                            border: none;
                        }

                        input {
                            font-size: 16px;
                            margin-bottom: 0;
                            color: $text-color;
                            font-weight: 400;
                            margin-top: -3px;
                            padding: 9px 12px;

                            input::placeholder {
                                color: $text-color;
                            }

                            input::-webkite-inline-placeholder {
                                color: $text-color;
                            }
                        }

                        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                            border: none;
                        }

                        div.MuiAutocomplete-root.MuiPopper-root.MuiAutocomplete-popperDisable.portal.css-1rndd9j-MuiPopper-root-MuiAutocomplete-popper {
                            font-size: 14px;
                            margin-bottom: 0;
                            color: $text-color;
                            font-weight: 400;
                        }

                        .css-1q60rmi-MuiAutocomplete-endAdornment {
                            top: calc(50% - 10px);
                            right: 20px;
                            color: $text-color;
                        }
                    }

                    &.border {
                        background-color: $white-color;
                        border: 1px solid $border-color;
                    }


                    &.border_temp {
                        padding: 0;

                        .MuiSelect-select {
                            padding: 16px 20px;
                            font-style: normal;
                        }

                        fieldset {
                            border-color: transparent;
                        }

                        .select_text {
                            font-style: normal;
                        }

                        svg {
                            right: 20px;
                            color: $text-color;
                        }
                    }

                    &::placeholder {
                        color: $text-color;
                    }
                }

                .form-line {
                    border: none;

                    .custom-file {
                        position: relative;
                        display: inline-block;
                        width: 100%;
                        height: 64px;
                        margin-bottom: 0;
                        font-size: 14px;
                        font-weight: 400 !important;

                        .custom-file-input {
                            position: relative;
                            z-index: 2;
                            width: 100%;
                            height: 35px;
                            margin: 0;
                            opacity: 0;
                        }

                        .custom-file-label {
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            z-index: 1;
                            height: auto;
                            padding: 20px;
                            line-height: 1.5;
                            color: #495057;
                            background-color: $grey-color;
                            border-radius: 7px;
                            font-size: 14px;
                            font-weight: 400 !important;
                        }

                        .custom-file-input:lang(en)~.custom-file-label::after {
                            content: "Browse";
                        }

                        .custom-file-label::after {
                            position: absolute;
                            right: 20px;
                            top: 14px;
                            z-index: 3;
                            display: block;
                            padding: 5px 10px;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 1.5;
                            color: $white-color;
                            content: "Browse";
                            background-color: $primary-color;
                            border-radius: 20px;
                        }
                    }

                    &::after {
                        border: none;
                    }
                }

                .btn-default {
                    padding: 2px 0;
                    font-size: 16px;
                    color: $text-color;
                    border: none;
                    background-color: transparent !important;
                }

                .bootstrap-select {
                    border: none !important;
                }
            }
        }

        .table_block {
            overflow: hidden;

            .form-group {
                margin-bottom: 0;
            }

            table.dataTable {
                margin: 0 !important;
                border-top: 1px solid $border-color !important;

                &.custom {

                    th,
                    td {
                        text-align: center;
                    }
                }

                th,
                td {
                    text-align: left;
                    vertical-align: middle;
                }

                tfoot {
                    td {
                        border-bottom: none;
                    }
                }

                tr {
                    // &:last-child {
                    //     td {
                    //         .table_detail {
                    //             .quality_score_detail {
                    //                 top: auto;
                    //                 bottom: 50px;
                    //                 left: auto;
                    //                 right: -10px;

                    //                 &::after {
                    //                     top: auto;
                    //                     bottom: -3px;
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }

                    td {
                        padding: 15px;
                        line-height: normal;

                        .switch_bar{
                            .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
                                color: $primary-color;
                            }

                            .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled{
                                color: $primary-one-color;
                            }

                            .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
                                background-color: $primary-color;
                            }
                        }

                        table {
                            width: 100%;
                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            margin: 0;

                            &.testimonial-msg {
                                word-break: break-all;
                            }

                            &.word_break {
                                white-space: nowrap;
                            }
                        }

                        .table_detail {
                            position: relative;

                            &:hover {
                                .quality_score_detail {
                                    visibility: visible;
                                }
                            }

                            &.name {
                                h6 {
                                    white-space: normal;
                                    word-break: break-word;
                                }

                                p {
                                    white-space: normal;
                                    word-break: break-word;
                                }
                            }

                            .quality_score_detail {
                                position: absolute;
                                top: 45px;
                                background: $text-color;
                                color: #fff;
                                padding: 15px;
                                right: -10px;
                                visibility: hidden;
                                z-index: 205;
                                border-radius: 4px;

                                &::after {
                                    width: 1em;
                                    height: 0.71em;
                                    box-sizing: border-box;
                                    background: $text-color;
                                    margin-top: -0.71em;
                                    position: absolute;
                                    right: 20px;
                                    transform: rotate(45deg);
                                    top: 7px;
                                    content: "";
                                    display: inline-block;

                                }

                                .quality_tag {
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: 10px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }

                                    h6 {
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: $white-color;
                                        margin: 0;
                                        font-family: "Atak Regular";
                                        margin-right: 10px;
                                    }

                                    p {
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: $white-color;
                                        margin: 0;
                                        opacity: 0.7;
                                    }
                                }
                            }

                            h6 {
                                font-size: 14px;
                                font-weight: 400;
                                font-family: Atak Regular;
                                margin: 0;
                                margin-bottom: 5px;
                                color: $text-color;
                                white-space: nowrap;

                                &.blog-title {
                                    white-space: initial;
                                    word-break: break-all;
                                }

                                &.tooltip_notes {
                                    .css-1k51tf5-MuiTooltip-tooltip {
                                        font-size: 14px;
                                    }
                                }

                                img {
                                    margin-left: 3px;
                                }

                                .props {
                                    display: inline-block;
                                    margin-right: 5px;
                                    padding: 3px 5px;
                                    background: $border-color;
                                    border-radius: 5px;

                                    &:last-child {
                                        margin-right: 0;
                                    }

                                    img {
                                        margin-left: 5px;
                                    }
                                }
                            }

                            p {
                                font-size: 12px;
                                font-weight: 400;
                                color: $text-color;
                                margin: 0;
                                margin-bottom: 5px;
                                opacity: 0.7;

                                .props {
                                    display: inline-block;
                                    margin-right: 5px;
                                    padding: 3px 5px;
                                    background: $border-color;
                                    border-radius: 5px;
                                    opacity: 1 !important;

                                    &:last-child {
                                        margin-right: 0;
                                    }

                                    img {
                                        margin-left: 5px;
                                    }
                                }
                            }

                            a {
                                &.status {
                                    padding: 5px 8px;
                                    border-radius: 3px;
                                    color: $white-color;
                                    display: inline-block;

                                    &.blue {
                                        background-color: $primary-color;
                                    }

                                    &.green {
                                        background-color: $secondary-color;
                                    }

                                    &.red {
                                        background-color: $secondary-one-color;
                                    }
                                }
                            }

                            span {
                                font-size: 10px;
                                font-weight: 400;
                                color: $text-color;
                                margin: 0;

                                &.opac {
                                    opacity: 0.7;
                                    display: block;
                                    text-align: center;
                                }
                            }

                            a.link {
                                width: 30px;
                                height: 30px;
                                display: block;
                                border-radius: 50%;
                                background-color: $white-color;
                                border: 1px solid $border-color;
                                display: flex;
                                justify-content: center;
                                margin: 0 auto;
                                margin-bottom: 5px;

                                &.blue {
                                    &:hover {
                                        background-color: $primary-color;
                                        border: 1px solid $primary-color;

                                        img {
                                            filter: brightness(0) invert(1);
                                        }
                                    }
                                }

                                &.green {
                                    &:hover {
                                        background-color: $secondary-color;
                                        border: 1px solid $secondary-color;

                                        img {
                                            filter: brightness(0) invert(1);
                                        }
                                    }
                                }

                                &.red {
                                    &:hover {
                                        background-color: $red-color;
                                        border: 1px solid $red-color;

                                        img {
                                            filter: brightness(0) invert(1);
                                        }
                                    }
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                img {
                                    width: 18px;
                                }
                            }

                            .progress_bar {
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                                border: 3px solid $secondary-color;
                                margin-left: 9px;
                                margin-bottom: 5px;
                            }
                        }
                    }
                }

                thead {
                    background-color: $primary-light-color;

                    th {
                        font-size: 14px;
                        font-weight: 700;
                        font-family: "Helvetica Now Display Bold";
                        color: $text-color;
                        padding: 20px 15px;

                        .table_heading {
                            h5 {
                                font-size: 14px;
                                font-weight: 700;
                                color: $text-color;
                                margin: 0;
                                margin-bottom: 5px;
                            }

                            p {
                                font-size: 10px;
                                font-weight: 700;
                                color: $text-color;
                                margin: 0;
                                opacity: 0.7;
                            }
                        }
                    }
                }

                tfoot {
                    background-color: $primary-light-color;

                    th {
                        font-size: 14px;
                        font-weight: 700;
                        font-family: "Helvetica Now Display Bold";
                        color: $text-color;
                        padding: 20px 15px;
                        border-bottom: none;

                        .table_heading {
                            h5 {
                                font-size: 14px;
                                font-weight: 700;
                                color: $text-color;
                                margin: 0;
                                margin-bottom: 5px;
                            }

                            p {
                                font-size: 10px;
                                font-weight: 700;
                                color: $text-color;
                                margin: 0;
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }

            .card {
                box-shadow: none;
                margin-bottom: 0;

                .icon {
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    background-color: $text-color;
                    border-radius: 50%;
                    padding: 5px;
                }

            }

            .input-group {
                margin-bottom: 0;
            }
        }
    }
}

.selected_amenities {
    padding: 0;

    ul {
        margin: 0;
        padding: 0;

        li {
            margin-right: 5px;
            padding: 5px 10px;
            border-radius: 5px;
            color: $text-color;
            margin-bottom: 5px;
            display: inline-block;
            list-style-type: none;
            background-color: $grey-color;
        }
    }
}

.form-group {

    &.icon_field {
        position: relative;

        .eye_icon {
            top: 15px;
            right: 15px;
            position: absolute;
        }
    }
}

.custom-bargraph {
    .recharts-rectangle {
        height: 50px;
    }
}